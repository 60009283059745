<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <ng-component [ngTemplateOutlet]="init" *ngIf="step === 0"></ng-component>

  <mat-stepper linear *ngIf="step !== 0" #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <mat-step [stepControl]="gettingStartedFormGroup">
      <form [formGroup]="gettingStartedFormGroup" class="flex flex-col">
        <ng-template matStepLabel>Getting started</ng-template>

        <div class="getting-started-container flex flex-row gap-8">
          <div class="flex flex-col gap-4 basis-[50%]" style="padding: 32px; width: 640px">
            <div class="flex flex-col gap-4">
              <div>
                <h3>Let's name your sheet</h3>
                <p>
                  You can add multiple sheets to your data capture.
                </p>
              </div>

              <mat-form-field>
                <mat-label>Name your sheet</mat-label>
                <input matInput formControlName="firstSheetName">
                <mat-error *ngIf="firstSheetName.hasError('required')">
                  Your sheet name is required
                </mat-error>
                <mat-error *ngIf="firstSheetName.hasError('invalidName')">
                  Your sheet name must not contain the following characters [ ? : / [ \ ] * ]
                </mat-error>
                <mat-error *ngIf="firstSheetName.hasError('minlength') || firstSheetName.hasError('maxlength')">
                  Your sheet name must be between 3 and 32 characters
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex flex-col gap-4 basis-[50%]" style="padding: 32px; width: 640px">
            <app-skeleton-data-capture [sheetName]="firstSheetName.value"
              [segments]="selectedSegments"></app-skeleton-data-capture>
          </div>
        </div>

        <div class="progress-container">
          <button mat-button matStepperPrevious (click)="cancelCreation()">Cancel</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="segmentsFormGroup">
      <form [formGroup]="segmentsFormGroup" class="flex flex-col">
        <ng-template matStepLabel>Select your segments</ng-template>

        <div class="getting-started-container flex flex-row gap-8">
          <div class="flex flex-col gap-4 basis-[50%]" style="padding: 32px; width: 640px">
            <div class="flex flex-col gap-4">
              <div>
                <h3>Let's link your segments</h3>
                <p>
                  Segments are groups of respondents that you can assign to your data capture sheet. You can assign one,
                  many or all segments within your project to your sheet.
                </p>
              </div>

              <mat-form-field>
                <mat-label>Choose segment(s)</mat-label>
                <mat-select multiple formControlName="segments">
                  <mat-option *ngFor="let segment of segments" [value]="segment.segmentId"
                    [disabled]="segment.occupied">
                    {{ segment.segmentName }}
                  </mat-option>
                </mat-select>
                <mat-error>At least one segment must be selected</mat-error>
              </mat-form-field>

              <p>
                We recommend creating one sheet per unique discussion guide. For example, if you are asking Payers
                different questions to KOLs, create one data sheet for Payers, and another for KOLs.
              </p>
            </div>
          </div>

          <div class="flex flex-col gap-4 basis-[50%]" style="padding: 32px; width: 640px">
            <app-skeleton-data-capture [sheetName]="firstSheetName.value"
              [segments]="selectedSegments"></app-skeleton-data-capture>
          </div>
        </div>

        <div class="progress-container">
          <button mat-button matStepperPrevious (click)="cancelCreation()">Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="themesFormGroup">
      <form [formGroup]="themesFormGroup" class="flex flex-col" (submit)="onThemesFormSubmit()">
        <ng-template matStepLabel>Add interview sections & questions</ng-template>

        <div class="discussion-guide-container flex flex-row gap-8">
          <div class="flex flex-col gap-4" style="padding: 32px"
            [ngClass]="collapsedPreview ? 'basis-[100%]' : 'basis-[50%]'">
            <div>
              <h3>Let's set up your discussion guide</h3>
              <p>
                Add your discussion guide interview sections and questions here. They will display as your column
                headers.
              </p>
            </div>

            <app-reorderable-expansion-panels [helperText]="true"></app-reorderable-expansion-panels>

            <ng-container *ngIf="themesFormSubmitted">
              <mat-error *ngIf="themesControl.hasError('noThemes')">
                At least one section and question must be added
              </mat-error>

              <mat-error *ngIf="themesControl.hasError('noThemeName')">
                Interview section name is required
              </mat-error>

              <mat-error *ngIf="themesControl.hasError('noQuestions')">
                At least one question is required for every interview section
              </mat-error>

              <mat-error *ngIf="themesControl.hasError('noQuestionName')">
                Question name is required for all questions
              </mat-error>

              <mat-error *ngIf="themesControl.hasError('duplicateThemeNames')">
                Interview section names must be unique
              </mat-error>

              <mat-error *ngIf="themesControl.hasError('duplicateQuestionNames')">
                Question names must be unique within each interview section
              </mat-error>
            </ng-container>
          </div>

          <div class="preview flex flex-col gap-4" style="padding: 32px"
            [ngClass]="collapsedPreview ? 'collapsed' : 'expanded'">
            <div class="flex flex-row gap-2 items-center">
              <i>{{ collapsedPreview ? 'Show preview' : 'Hide preview' }} </i>
              <mat-icon (click)="setCollapsedState()" class="collapsable">
                {{collapsedPreview ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}}
              </mat-icon>
            </div>
            <app-data-sheet [class.collapsed]="collapsedPreview" [sheet]="themes"
              [sheetHeaders]="sheetHeaders"></app-data-sheet>
          </div>
        </div>

        <div class="progress-container">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="additionalFieldsFormGroup">
      <form [formGroup]="additionalFieldsFormGroup" class="flex flex-col">
        <ng-template matStepLabel>Add additional fields</ng-template>

        <div class="additional-fields-container flex flex-row gap-8">
          <div class="flex flex-col gap-4" style="padding: 32px">
            <div>
              <h3>Add additional fields</h3>
              <p>
                Add fields to include in your data capture sheet. These fields will show under the respondent’s name in
                your sheet. When exported, they will appear as additional columns.
              </p>
            </div>

            <div class="additional-fields flex flex-row gap-8">
              <div class="flex flex-col gap-1">
                <b>Optional fields</b>
                <mat-checkbox formControlName="geography">Geography</mat-checkbox>
                <mat-checkbox formControlName="profileType">Profile type</mat-checkbox>
                <mat-checkbox formControlName="dateAndTimeOfCall">Date and time of call</mat-checkbox>
                <mat-checkbox formControlName="interviewerName">
                  <div class="flex flex-row items-center content-center gap-1">
                    <span>Interviewer name</span>
                    <mat-icon class="info" matTooltip="Free text field">info_outline</mat-icon>
                  </div>
                </mat-checkbox>
                <mat-checkbox formControlName="notetakerName">
                  <div class="flex flex-row items-center content-center gap-1">
                    <span>Notetaker name</span>
                    <mat-icon class="info" matTooltip="Free text field">info_outline</mat-icon>
                  </div>
                </mat-checkbox>
                <mat-checkbox formControlName="recruitmentSource">
                  <div class="flex flex-row items-center content-center gap-1">
                    <span>Recruitment source</span>
                    <mat-icon class="info" matTooltip="Displays the source for respondents added through file upload">
                      info_outline
                    </mat-icon>
                  </div>
                </mat-checkbox>
              </div>

              <div class="flex flex-col gap-1">
                <b>Include in export</b>
                <mat-checkbox formControlName="expertBio">Expert bio</mat-checkbox>
              </div>
            </div>
          </div>

          <div class="preview flex flex-col basis-1 gap-4" style="padding: 32px">
            <i>Preview</i>
            <app-data-sheet [sheet]="themes" [sheetHeaders]="sheetHeaders"></app-data-sheet>
          </div>
        </div>

        <div class="progress-container">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <form class="flex flex-col">
        <ng-template matStepLabel>Preview</ng-template>

        <div class="preview-container flex flex-col gap-4">
          <i>Preview</i>

          <app-data-sheet [sheet]="themes" [sheetHeaders]="sheetHeaders"></app-data-sheet>

          <p>
            Please note: Once your sheet is published, you will be able to add new and delete existing questions. If you
            need to edit an existing question once published, you will need to delete and re-add it. ​
          </p>
        </div>

        <div class="progress-container">
          <button mat-button matStepperPrevious [disabled]="creatingSheet">
            Back
          </button>
          <button mat-button matStepperNext (click)="complete()" [disabled]="creatingSheet">
            {{ creatingSheet ? 'Publishing sheet...' : 'Publish' }}
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</ng-container>

<ng-template #init>
  <div class="step-0 flex flex-col content-center justify-center gap-16">
    <div class="content-center justify-center">
      <h2>Set up your Data Capture in 4 easy steps</h2>
      <button mat-flat-button color="primary" (click)="getStarted()">Get started</button>
    </div>
  </div>
</ng-template>