<div class="sheet">
  <div class="excel-table" *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <table class="excel-table" *ngIf="!loading">
    <thead>
      <tr>
        <th colspan="1">
          <button mat-button (click)="refreshData()" *ngIf="refreshDataTrigger">
            <mat-icon class="refresh">refresh</mat-icon>
            <span class="refreshed">{{ refreshedDataTime | date: 'medium' }}</span>
          </button>
        </th>
        <th class="theme-header" [attr.colspan]="group.questions.length || 1" *ngFor="let group of groupedThemes">
          {{group.theme}}
        </th>
      </tr>
      <tr class="header-row">
        <th>Respondent</th>
        <ng-container *ngFor="let group of groupedThemes">
          <th *ngFor="let question of group.questions">
            {{question.question}}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let respondent of respondents">
        <td class="respondent" (click)="viewRespondent(respondent)">
          <a>
            {{respondent.firstName}} {{respondent.lastName}}
          </a>
          <br *ngIf="sheetHeaders.geography || sheetHeaders.profile_type || sheetHeaders.date_and_time_of_call">
          <ng-container *ngIf="sheetHeaders.geography && !sheetHeaders.profile_type">
            <span>{{respondent.geography}}</span><br>
          </ng-container>
          <ng-container *ngIf="!sheetHeaders.geography && sheetHeaders.profile_type">
            <span>{{respondent.segment}}</span><br>
          </ng-container>
          <ng-container *ngIf="sheetHeaders.geography && sheetHeaders.profile_type">
            <span>{{respondent.geography}} - {{respondent.segment}}</span><br>
          </ng-container>
          <span *ngIf="sheetHeaders.date_and_time_of_call">
            {{respondent.callDate | date:'d MMM y, h:mm a'}}
          </span>
        </td>

        <ng-container *ngFor="let group of groupedThemes">
          <td class="question" *ngFor="let question of group.questions">
            <span *ngIf="respondent.answers[question.question_id]">
              <span *ngIf="!respondent.answers[question.question_id].editing">
                {{ respondent.answers[question.question_id].answer }}
              </span>

              <div *ngIf="respondent.answers[question.question_id].editing">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Answer</mat-label>
                  <textarea matInput [(ngModel)]="respondent.answers[question.question_id].answer"></textarea>
                </mat-form-field>
              </div>
            </span>

            <span *ngIf="!respondent.answers[question.question_id]">
              Processing
            </span>

            <div *ngIf="respondent.answers[question.question_id]" class="action-container flex flex-row gap-2">
              <ng-container *ngIf="respondent.answers[question.question_id].editing">
                <mat-icon matTooltip="Save" (click)="saveAnswer(respondent.answers[question.question_id])">
                  check
                </mat-icon>
                <mat-icon matTooltip="Cancel" (click)="cancelEdit(respondent.answers[question.question_id])">
                  close
                </mat-icon>
              </ng-container>
              <ng-container *ngIf="!respondent.answers[question.question_id].editing">
                <mat-icon matTooltip="Edit in-line" (click)="edit(respondent.answers[question.question_id])">
                  edit
                </mat-icon>
                <mat-icon matTooltip="Copy"
                  (click)="copy(respondent.answers[question.question_id]); $event.stopPropagation()">
                  copy
                </mat-icon>
              </ng-container>
            </div>

            <div *ngIf="respondent.answers[question.question_id]?.answer"
              class=" flex flex-row flex-wrap items-center justify-center gap-2">
              <span *ngFor="let citation of respondent.answers[question.question_id].citation; index as i"
                class="citation" (click)="viewCitation(respondent, citation)" matTooltip="View reference">
                Reference {{i+1}}
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>