<div class="flex justify-between content-center">
  <div class="flex flex-col">
    <h3 mat-dialog-title>Single expert view</h3>
  </div>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div class="view-respondent-subtitle flex flex-row items-center gap-1">
  <mat-icon (click)="navigateBack()" matTooltip="Prior expert">chevron_left</mat-icon>
  <span><b>{{selectedRespondent.firstName}} {{selectedRespondent.lastName}}</b> | {{selectedRespondent.geography}} -
    {{selectedRespondent.segment}} | {{selectedRespondent.callDate | date:'d MMM y, h:mm a'}}</span>
  <mat-icon (click)="navigateForward()" matTooltip="Next expert">chevron_right</mat-icon>
</div>

<mat-dialog-content>
  <app-loader *ngIf="loading"></app-loader>

  <mat-tab-group *ngIf="!loading">
    <mat-tab label="Response grid">
      <div class="flex flex-row">
        <div class="answers-container flex flex-col flex-[50%] gap-4">
          <div *ngFor="let group of groupedThemes" class="flex flex-col gap-4">
            <u class="theme">{{group.theme}}</u>

            <div *ngFor="let question of group.questions" [attr.id]="question.mappedQuestionId"
              class="flex flex-col gap-4">
              <b>{{question.question}}</b>

              <mat-form-field>
                <textarea matInput [value]="selectedRespondent.answers[question.question_id]?.answer || ''"
                  [disabled]="!selectedRespondent.answers[question.question_id]"></textarea>
              </mat-form-field>

              <div class="flex flex-wrap gap-2">
                <span
                  *ngFor="let citation of selectedRespondent.answers[question.question_id]?.citation || []; index as i"
                  (click)="navigateCitation(citation)" class="citation" matTooltip="View reference">
                  Reference {{i+1}}

                  {{citation.startTimestamp}}
                  {{citation.endTimestamp}}
                </span>

              </div>
            </div>
          </div>
        </div>
        <div class="transcript-container flex-[50%]">
          <div class="transcript-container flex flex-col flex-grow">
            <div class="transcript-content" *ngIf="transcriptFile && transcriptChunks.length">
              <div class="chunk" *ngFor="let chunk of transcriptChunks" [class.highlight]="chunk.highlighted">
                <div class="flex flex-row justify-between items-center">
                  <span>{{chunk.start}} --> {{chunk.end}}</span>

                  <div class="flex flex-row items-center content-center gap-1">
                    <mat-icon (click)="updateQuote(chunk)"
                      [matTooltip]="chunk.savedQuoteId ? 'Unfavorite' : 'Favorite'">
                      {{chunk.loading ? 'autorenew' : chunk.savedQuoteId ? 'star' : 'star_outlined'}}
                    </mat-icon>
                  </div>
                </div>
                <span><b>{{chunk.speaker}}</b>: {{chunk.content}}</span>
              </div>
            </div>
          </div>

          <div class="transcript-container" style="min-height: 125px">
            <div class="flex items-center justify-between">
              <b>Audio</b>
              <div *ngIf="transcriptFile && transcriptChunks.length" class="flex gap-2 items-center">
                <span>Auto scroll</span>
                <mat-slide-toggle [(ngModel)]="autoScroll"></mat-slide-toggle>
              </div>
            </div>
            <span *ngIf="!audioData && !transcriptFile">Please select a file to play</span>
            <span *ngIf="!audioData && transcriptFile">Audio file is unavailable</span>

            <audio *ngIf="audioData" [audioTime]="audioStartTime" controls (timeupdate)="updateTime($event)">
              <source [src]="audioData" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Call summary">
      <div style="padding: 16px">
        <app-echo [file]="selectedRespondent.echoSummary" [showEchoAsk]="false"></app-echo>
      </div>
    </mat-tab>

    <mat-tab label="Saved quotes">
      <div style="padding: 16px">
        <app-expert-quotes [expertId]="selectedRespondent.respondentId" [showEchoAsk]="false"></app-expert-quotes>
      </div>
    </mat-tab>

    <mat-tab label="Expert files">
      <div style="padding: 16px">
        <app-expert-files [files]="selectedRespondent.files" [isAuthorised]="true"></app-expert-files>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions class="flex gap-2">
  <button class="flex-1 primaryColor-border" mat-stroked-button mat-dialog-close>
    Close window
  </button>
  <button class="flex-1" mat-flat-button color="primary" (click)="confirm()">
    Confirm
  </button>
</mat-dialog-actions>